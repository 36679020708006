import Axios from "axios";
import token from "./setToken";

export default {
  async search(searchCondition) {
    let params = new URLSearchParams();
    params.append('searchCondition', JSON.stringify(searchCondition));

    try {
      // await token.checkToken();
      const res = await Axios.post("/api/CityDataAnswerList", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("月次報告データを取得できません[search]");
      throw error;
    }
  },

  async getData(surveyId, memberNo, targetMonth) {
    let params = new URLSearchParams();
    params.append("surveyId", JSON.stringify(surveyId));
    params.append("memberNo", JSON.stringify(memberNo));
    params.append("targetMonth", JSON.stringify(targetMonth));

    try {
      // await token.checkToken();
      const res = await Axios.post("/api/CityDataAnswerData", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("月次報告データを取得できません[getData]");
      throw error;
    }
  },

  async setData(answer, answer8, answer9, sumAnswer9, answerImported, sumAnswerImported) {
    let params = new URLSearchParams();
    params.append("answer", JSON.stringify(answer));
    params.append("answer8", JSON.stringify(answer8));
    params.append("answer9", JSON.stringify(answer9));
    params.append("sumAnswer9", JSON.stringify(sumAnswer9));
    params.append("answerImported", JSON.stringify(answerImported));
    params.append("sumAnswerImported", JSON.stringify(sumAnswerImported));

    try {
      // await token.checkToken();
      const res = await Axios.post("/api/CityDataAnswer", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("月次報告データを設定できません[setData]");
      throw error;
    }
  },

  /** 回答状況 */
  async getSurveyAnsStatus(searchCondition) {
    let params = new URLSearchParams();
    params.append('searchCondition', JSON.stringify(searchCondition));

    try {
      // await token.checkToken();
      const res = await Axios.post("/api/CityDataAnswerStatus", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("月次報告データを取得できません[getSurveyAnsStatus]");
      throw error;
    }
  },

  /** 回答データチェック 一覧取得 */
  async getSurveyAnsCheckDataList(searchCondition) {
    let params = new URLSearchParams();
    params.append('searchCondition', JSON.stringify(searchCondition));

    try {
      // await token.checkToken();
      const res = await Axios.post("/api/CityDataAnsCheckDataList", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("月次報告データを取得できません[getSurveyAnsCheckDataList]");
      throw error;
    }
  },

  /** 回答データチェック 個別データ取得(事業所) */
  async getAnsCheckDataMember(searchCondition) {
    let params = new URLSearchParams();
    params.append('searchCondition', JSON.stringify(searchCondition));
    
    try {
      // await token.checkToken();
      const res = await Axios.post("/api/CityDataAnsCheckDataMember", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("月次報告データを取得できません[getAnsCheckDataMember]");
      throw error;
    }
  },

  /** 回答データチェック 個別データ取得(12都市) */
  async getAnsCheckData12city(searchCondition) {
    let params = new URLSearchParams();
    params.append('searchCondition', JSON.stringify(searchCondition));
    
    try {
      // await token.checkToken();
      const res = await Axios.post("/api/CityDataAnsCheckData12city", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("月次報告データを取得できません[getAnsCheckDataAssociation]");
      throw error;
    }
  },

  /** 回答データチェック 個別データ取得(府県別) */
  async getAnsCheckDataPrefSum(searchCondition) {
    let params = new URLSearchParams();
    params.append('searchCondition', JSON.stringify(searchCondition));
    
    try {
      // await token.checkToken();
      const res = await Axios.post("/api/CityDataAnsCheckDataPrefSum", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("月次報告データを取得できません[getAnsCheckDataPrefSum]");
      throw error;
    }
  },

  /** 回答データチェック 個別データ更新 */
  async setDataThisPrev(thisAnswer, prevAnswer) {
    let params = new URLSearchParams();
    params.append("thisAnswer", JSON.stringify(thisAnswer));
    params.append("prevAnswer", JSON.stringify(prevAnswer));

    try {
      // await token.checkToken();
      const res = await Axios.post("/api/CityDataAnswerThisPrev", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("月次報告データを設定できません[setDataThisPrev]");
      throw error;
    }
  },

  /** 集計(都道府県協会) */
  async sumAssociation(targetMember, targetMonth, association) {
    let params = new URLSearchParams();
    params.append("targetMember", JSON.stringify(targetMember));
    params.append("targetMonth", JSON.stringify(targetMonth));
    params.append("association", JSON.stringify(association));

    try {
      // await token.checkToken();
      const res = await Axios.post("/api/CityDataAnswerSumAssociation", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("月次報告データ集計を設定できません[sumAssociation]");
      throw error;
    }
  },
  
};
